.contact {
    // background: linear-gradient(258.27deg, #0E59A3 0%, #018BD9 100%);
    
    &__blocks {
        margin-top: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            &-paragraph {
                font-size: 40px;
                font-weight: 700;
                line-height: 150%;

                &--border {
                    padding: 9px 15px;
                    border: 1px solid white;
                    border-radius: 100px;
                }
            }

            &-contacts {
                margin-top: 30px;
                display: flex;
                align-items: center;

                &-link {
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 30px;
                    font-weight: 500;
                    transition: .3s ease-out;
                    &:hover {
                        border-bottom: 1px solid white;
                    }

                    &-icon {
                        margin-right: 10px;
                        width: 39px;
                        height: 39px;
                    }
                }
            }
        }

        &-form {
            display: flex;
            flex-direction: column;
            padding: 50px 40px;
            background: rgba(255, 255, 255, 0.17);
            border-radius: 34px;
            transition: 0.4s all;
            &:hover {
                box-shadow: rgb(255, 255, 255) 0px 0px 0px 3px;
            }

            &-title {
               font-size: 22px; 

               &--header {
                   margin-bottom: 30px;
                   font-size: 30px;
                   font-weight: 700;
                   text-align: center;
               }
            }

            &-input {
                margin-top: 10px;
                margin-bottom: 40px;
                padding-left: 20px;
                width: 350px;
                height: 60px;
                font-size: 24px;
                color: #fff;
                border: none;
                background: rgba(255, 255, 255, 0.15);
                backdrop-filter: blur(10px);
                border-radius: 26px;
                outline-color: white;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.562);
                }
            }

            &-btn {
                height: 60px;
                color: #000;
                background: #fff;
            }
        }
    }
}

@media (max-width: 1024px) {
    .contact {
        &__blocks {
            flex-direction: column;

            &-text {
                margin-bottom: 30px;

                &-paragraph {
                    font-size: 35px;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .contact {
        &__blocks {
            &-form {
                width: 100%;
                align-items: center;
                box-sizing: border-box;

                &-btn {
                    width: 100%;
                }

                &-input {
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            &-text {
                margin-bottom: 20px;
                margin-bottom: 30px;

                &-paragraph {
                    font-size: 30px;
                }

                &-contacts {
                    flex-direction: column;

                    &-link {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .contact {
        &__blocks {
            margin-top: 40px;

            &-text {
                &-paragraph {
                    font-size: 26px;

                    &--border {
                        padding: 5px 15px;
                    }
                }
            }

            &-form {
                padding: 30px;

                &-title {
                    &--header {
                        font-size: 24px;
                    }
                }

                &-input {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .contact {
        &__blocks {
            margin-top: 30px;

            &-text {
                &-paragraph {
                    font-size: 24px;
                }
            }

            &-form {
                padding: 30px 10px;

                &-input {
                    height: 50px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    border-radius: 20px;
                }
            }
        }
    }
}

@media (max-width: 280px) {
    .contact {
        &__blocks {
            &-text {
                &-contacts {
                    &-link {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}