.wrapper {
    max-width: 1170px;
    margin: 0 auto;
}

@media (max-width: 1024px) {
    .wrapper {
        max-width: 768px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        max-width: 425px;
    }
}

@media (max-width: 425px) {
    .wrapper {
        max-width: 320px;
    }
}

@media (max-width: 320px) {
    .wrapper {
        max-width: 280px;
    }
}