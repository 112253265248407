.wrap {
    position: fixed;
    top: -10px;
    width: 100%;
    height: 101vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.183);
  }
  
  .modal {
    min-width: 320px;
    width: 320px;
    background-color: #fff;
    padding: 2em 3em;
    text-align: center;
    border-radius: .5em;
    box-sizing: border-box;
	-webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .is-active {
    display: block;
  }
  
  .modal-image {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #48DB71;
    padding: 11px 10px 2px;
    margin-bottom: 2em;
    box-sizing: border-box;
  }

  .modal-image_check {
    width: 30px;
  }
  
  .h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: .5em;
    color: #000;
  }
  
  .p {
    margin-bottom: 2em;
    color: #666;
  }
  
  .btn {
    font-size: 1.25em;
    font-weight: bold;
    background-color: #197DBE;
    border: none;
    padding: .5em 1em;
    color: #fff;
    box-shadow: 0 0 0 2px #197DBE inset;
    border-radius: .25em;
    cursor: pointer;
    transition: background .4s ease, color .4s ease;
  }
  
  .btn:hover {
    box-shadow: 0 0 0 2px #197DBE inset;
    color: #197DBE;
    background-color: transparent;
  }
  
  @-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-top-fwd {
    0% {
      -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
              transform: translateY(-600px) rotateX(-30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% 1400px;
              transform-origin: 50% 1400px;
      opacity: 1;
    }
  }
  
  