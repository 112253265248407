.footer {
    margin-top: 100px;

    &__links {
        margin-bottom: 90px;
        display: flex;

        &-logo {
            margin-right: 50px;
            background: linear-gradient(180deg, #197DBE 0%, #4DA6FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            font-size: 100px;
            font-family: PerfectlyAmicable, serif;
        }

        &-link-block {
            display: flex;

            &-item {
                margin-right: 80px;
                display: flex;
                flex-direction: column;

                &-title {
                    margin-bottom: 30px;
                    font-size: 24px;
                    font-weight: 700;
                    text-decoration: underline;
                }

                &-link {
                    margin-bottom: 30px;
                    color: #000;
                    font-size: 20px;
                    font-weight: 500;
                    text-decoration: none;
                    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover {
                        color: #197DBE;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        height: 30px;

        &-gray {
            width: 30%;
            height: 100%;
            background: #018BD9;
        }

        &-black {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: 100%;
            font-weight: 700;
            color: #fff;
            background: #0E5BA5;
        }
    }
}

@media (max-width: 1024px) {
    .footer {
        &__links {
            margin-bottom: 70px;
            flex-direction: column;

            &-logo {
                margin-right: 0;
                margin-bottom: 40px;
            }
        }
    }
}

@media (max-width: 768px) {
    .footer {
        margin-top: 70px;
        
        &__links {
            margin-bottom: 50px;

            &-link {
                &-block {
                    flex-wrap: wrap;

                    &-item {
                        margin-bottom: 10px;
                        width: 49%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .footer {
        &__links {
            &-logo {
                font-size: 50px;
            }

            &-link {
                &-block {
                    &-item {
                        &-link {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}