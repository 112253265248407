.our-works {
    padding: 60px 0;

    &__title {
        margin-bottom: 40px;
    }

    &__item {
        width: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #000;
        transition: .3s all;
        &:hover {
            background: rgba(255, 255, 255, 0.149);
        }

        &-img {
            width: 350px;
        }

        &-name {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &-img {
                margin-right: 10px;
                width: 46px;
            }

            &-title {
                font-size: 28px;
                font-weight: 500;
            }
        }

        &-subtitle {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 500;
        }

        &-btn {
            padding: 8px 14px;
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 100px;
            font-size: 18px;
            font-weight: 700;
            background-color: #197DBE;
            color: #fff;
            text-decoration: none;
            box-sizing: border-box;
        }
    }
}

@media (max-width: 1024px) {
    .our-works {
        &__item {
            &-img {
                width: 230px;
            }

            &-name {
                &-img {
                    margin-right: 7px;
                    width: 40px;
                }
    
                &-title {
                    font-size: 24px;
                }
            }
    
            &-subtitle {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 800px) {
    .our-works {
        &__item {
            &-img {
                width: 330px;
            }

            &-name {
                &-img {
                    margin-right: 7px;
                    width: 45px;
                }
    
                &-title {
                    font-size: 26px;
                }
            }
    
            &-subtitle {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 425px) {
    .our-works {
        &__item {
            &-img {
                width: 230px;
            }

            &-name {
                &-img {
                    margin-right: 7px;
                    width: 37px;
                }
    
                &-title {
                    font-size: 23px;
                }
            }
    
            &-subtitle {
                font-size: 14px;
            }
        }
    }
}