.works-page {
    padding-top: 40px;

    &__header {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title-block {
            display: flex;
            align-items: center;

            &-img {
                margin-right: 30px;
                width: 100px;
                height: 100px;
            }

            &-title {
                font-size: 50px;
                font-weight: 500;
            }
        }

        &-link {
            font-size: 20px;
            font-weight: 700;

            &--icon {
                margin-left: 7px;
            }
        }
    }

    &__subtitle {
        font-size: 26px;
        font-weight: 500;
        line-height: 150%;
    }

    &__text {
        padding-top: 15px;
        line-height: 1.5;
    }

    &__heading {
        margin-top: 50px;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 500;
    }

    &__gallery {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        gap: 10px;
        &-item {
            &-img {
                width: 100%;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 768px) {
    .works-page {
        &__header {
            flex-wrap: wrap;

            &-title-block {
    
                &-img {
                    margin-right: 15px;
                    width: 50px;
                    height: 50px;
                }
    
                &-title {
                    font-size: 30px;
                }
            }
    
            &-link {
                padding: 8px 15px;
                font-size: 18px;
    
                &--icon {
                    width: 15px;
                    margin-left: 5px;
                }
            }
        }
    
        &__subtitle {
            font-size: 24px;
        }

        &__text {
            font-size: 15px;
        }
    
        &__heading {
            margin-top: 30px;
            margin-bottom: 15px;
            font-size: 28px;
        }
    
        &__gallery {
            grid-template-columns: 50% 50%;
        }
    }
}

@media (max-width: 425px) {
    .works-page {
        &__header {
            &-title-block {
                &-img {
                    margin-right: 10px;
                    width: 35px;
                    height: 35px;
                }
    
                &-title {
                    font-size: 23px;
                }
            }
    
            &-link {
                padding: 6px 12px;
                font-size: 14px;
            }
        }
    
        &__subtitle {
            font-size: 18px;
        }

        &__text {
            font-size: 14px;
        }
    
        &__heading {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 24px;
        }
    }
}