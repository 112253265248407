.bmenu {
    position: absolute;
    top: 70px;
    right: 20px;
    width: 75%;
    padding: 25px 25px 5px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid black;
    background: #fff;
    box-sizing: border-box;
        -webkit-animation: slide-in-blurred-tr 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
                animation: slide-in-blurred-tr 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;

    &__link {
        margin-bottom: 20px;
        width: 100%;
        text-align: right;
        color: #000;
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid black;
        text-decoration: none;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

@-webkit-keyframes slide-in-blurred-tr {
    0% {
      -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
              transform: translate(1000px, -1000px) skew(-80deg, -10deg);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, 0) skew(0deg, 0deg);
              transform: translate(0, 0) skew(0deg, 0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-blurred-tr {
    0% {
      -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
              transform: translate(1000px, -1000px) skew(-80deg, -10deg);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(0, 0) skew(0deg, 0deg);
              transform: translate(0, 0) skew(0deg, 0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
  }
  
  
  
  