.button {
    padding: 16px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    background: #197DBE;
    border: none;
    border-radius: 23px;

    font-family: NeueMachina, serif;
    box-sizing: border-box;
    cursor: pointer;
}