.intro {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;

    &__title-block {
        &-title {
            font-size: 50px;
            font-weight: 500;
            line-height: 55px;

            &--border {
                display: inline-block;
                margin-top: 10px; 
                margin-bottom: 10px;
                padding: 3px 10px;
                border: 2px solid #197DBE;
                // outline: 2px solid #197DBE;
                // outline-color: #197DBE;
                // outline-offset: 1px;    
                border-radius: 100px;
                box-sizing: content-box;
            }
        }

        &-subtitle {
            margin: 40px 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;

            &--drivery {
                font-size: 24px;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }

    &__img-block {
        &-img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0px;
            margin: auto 0;
            max-width: 1000px;
            width: 50%;
            -webkit-animation: slide-left 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: slide-left 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        }
    }

    &__btn {
        width: 250px;
        height: 55px;

        border-radius: 100px;
        font-size: 20px;
        font-weight: 500;
    }
}

@media (max-width: 768px) {
    .intro {
        padding: 30px 0;
        margin-bottom: 30px;
        flex-direction: column-reverse;
        height: auto;
        box-sizing: border-box;

        &__title {
            &-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                &-title {
                    margin-top: 30px;
                    font-size: 45px;

                    &--border {
                        padding: 3px 10px;
                    }
                }

                &-subtitle {
                    margin: 25px 0;
                }
            }
        }

        &__img-block {
            display: flex;
            align-items: center;
            justify-content: center;
            
            &-smallimg {
                width: 100%;
            }
        }
    }
}

@media (max-width: 375px) {
    .intro {
        &__title {
            &-block {
                &-title {
                    font-weight: 600;
                    font-size: 30px;

                    &--border {
                        padding: 0 10px;
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .intro {
        &__title {
            &-block {
                &-title {
                    font-size: 27px;
                }
            }
        }
    }
}

@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(600px);
              transform: translateX(600px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(600px);
              transform: translateX(600px);
    }
    100% {
      -webkit-transform: translateX(0px);
              transform: translateX(0px);
    }
  }