.what-we-do {
    margin-bottom: 50px;

    &__cards {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        &-item {
            width: 30%;
            padding: 50px 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 18px solid #197DBE;
            border-radius: 34px 34px 20px 20px;
            background: #fff;
            box-sizing: border-box;
            transition: 0.4s all;
            -webkit-animation: scale-up-center-out 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-center-out 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            &:hover {
                box-shadow: #0e58a332 0px 60px 40px -7px;
                -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            }

            &-img {
                margin: 0 auto;
                width: 150px;
                height: 150px;
            }

            &-title {
                margin-top: 30px;
                height: 90px;
                font-size: 24px;
                font-weight: 700;
                line-height: 116%;
            }

            &-subtitle {
                margin-top: 20px;
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 1024px) {
    .what-we-do {
        &__cards {
            &-item {
                width: 31%;
                padding: 40px 25px;
                border-radius: 24px;

                &-img {
                    width: 100px;
                    height: 100px;
                }

                &-title {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .what-we-do {
        &__cards {
            flex-direction: column;

            &-item {
                margin-bottom: 20px;
                padding: 30px 20px;
                width: 100%;
                flex-direction: row;
                align-items: center;
                border-bottom: 15px solid #197DBE;
                
                &-img {
                    margin-right: 10px;
                }

                &-title {
                    margin-top: 0;
                    height: auto;
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .what-we-do {
        &__cards {
            &-item {
                padding: 20px 10px;

                &-img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}

@-webkit-keyframes scale-up-center {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes scale-up-center {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  
  @-webkit-keyframes scale-up-center-out {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
  }
  @keyframes scale-up-center-out {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
  }