.breadcrumbs {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &__back {
        &-btn {
            &-img {
                padding: 5px 15px;
                border-radius: 100px;
                background-color: #197DBE;
                transform: scaleX(-1);
            }
        }
    }

    &__links {
        margin: 0 10px 0 15px;
        &-item {
            margin-right: 10px;
            color: #000;
            text-decoration: none;
            font-size: 20px;

            &::before {
                content: '/';
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 768px) {
    .breadcrumbs {
        &__links {
            &-item {
                line-height: 1.7;
                font-size: 15px;
            }
        }
    }
}