.link {
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.disabled {
  background: rgba(133, 211, 255, 0.5) !important;
  color: #fff !important;
}

.scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.notification {
  border-radius: 13px !important;
  box-shadow: none !important;
  font-family: Montserrat !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  color: #000 !important;
  background: rgba(255, 255, 255, 0.15) !important;
  border: 1px solid rgba(158, 158, 158, 0.441) !important;
  backdrop-filter: blur(10px) !important;
  font-family: NeueMachina, serif !important;

  &-success {
    background: rgba(255, 255, 255, 0.15) !important;
  }

  &-info {
    background: rgba(255, 255, 255, 0.66) !important;
  }
}