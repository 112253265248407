.header {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1000;


    &__logo {
        background: linear-gradient(180deg, #197DBE 0%, #4DA6FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-size: 75px;
        font-family: PerfectlyAmicable, serif;
    }

    &__links {
        display: flex;
        margin-left: 40px;

        &-item {
            margin-right: 5px;
            padding: 7px 19px;
            color: #000;
            border-radius: 23px;
            transition: .2s all;
            box-sizing: border-box;
            
            &:hover {
                box-shadow: -0px 0px 1px 1px #197DBE;
            }
        }
    }

    &__call {
        padding: 6px 15px;
        display: flex;
        align-items: center;
        border-radius: 23px;
        color: #fff;
        background: #197DBE;
        box-sizing: border-box;

        &-icon {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }
}

@media (max-width: 1024px) {
    .header {
        justify-content: space-between;
    }
}

@media (max-width: 768px) {
    .header {
        &__logo {
            font-size: 46px;
        }

        &__links {
            display: flex;
            align-items: center;
            margin-left: 10px;

            &-item {
                padding: 5px;
                font-size: 15px;
            }
        }

        &__call {
            padding: 5px 10px;
            font-size: 15px;
        }
    }
}


@media (max-width: 768px) {
    .header {
        &__border {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 18px;
            box-sizing: border-box;
            -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
    }
}

// @media (max-width: 425px) {
//     .header {
//         justify-content: space-between;
//     }
// }

@media (max-width: 280px) {
    .header {
        padding-left: 10px;
        width: 90%;
    }
}

@-webkit-keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }