.about-us {
    padding: 60px 0;
    // background: linear-gradient(180deg, #008BD9 0%, #197DBE 100%);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    background: linear-gradient(270deg, #46bed6, #2039b3, #197DBE); 
    background-size: 600% 600%;
    animation: example 30s ease infinite;
    -webkit-animation: example 30s ease infinite;
    -moz-animation: example 30s ease infinite;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__subtitle {
        margin-top: 30px;
        margin-bottom: 50px;
        font-size: 20px;
        font-weight: 500;
    }

    &__cards {
        display: flex;
        justify-content: space-between;

        &-item {
            width: 26%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &-block {
                padding: 40px 30px;
                margin-bottom: 26px;
                border-radius: 23px;
                background: rgba(255, 255, 255, 0.17);
                backdrop-filter: blur(10px);
                transition: .3s ease-in-out;
                cursor: pointer;
                &:hover {
                    color: #197DBE;
                    background: #fff;
                }

                &-number {
                    font-size: 100px;
                    font-weight: 700;
                }

                &-text {
                    font-size: 60px;
                    font-weight: 700;
                }
            }

            &-subtitle {
                font-size: 26px;
                line-height: 120%;
            }
        }
    }
}

@media (max-width: 1024px) {
    .about-us {
        &__cards {
            &-item {
                &-block {
                    padding: 30px 20px;

                    &-number {
                        font-size: 70px;
                    }

                    &-text {
                        font-size: 40px;
                    }
                }
                
                &-subtitle {
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .about-us {
        text-align: center;

        &__subtitle {
            line-height: 140%;
        }

        &__cards {
            flex-direction: column;
            align-items: center;

            &-item {
                margin-bottom: 30px;
                width: 100%;
            }
        }
    }
}

@keyframes example {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-webkit-keyframes example {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes example {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }