.title {
    font-size: 45px;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 320px) {
    .title {
        font-size: 35px;
    }
}