.our-tools {
    padding: 60px 0;

    &__title {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 40px;
        font-weight: 500;
        text-decoration: underline;
    }

    &__cards {
        display: flex;
        justify-content: space-between;

        &-item {
            padding: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 24%;
            background: #fff;
            border-radius: 34px;
            box-sizing: border-box;
            -webkit-animation: shadow-pop-bl-out 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
            animation: shadow-pop-bl-out 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
            &:hover {
                -webkit-animation: shadow-pop-bl 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
                animation: shadow-pop-bl 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
            }

            &--big {
                width: 49%;
            }

            &-img {
                width: 125px;
                height: 125px;
            }

            &-title {
                margin-top: 15px;
                font-size: 35px;
                font-weight: 500;
            }
        }
    }

    &__margin {
        margin-top: 20px;
    }
}

@media (max-width: 1024px) {
	.our-tools {
		&__title {
			font-size: 30px;
		}

		&__cards {
			&-item {
				padding: 20px;
				border-radius: 25px;

				&-img {
					width: 90px;
					height: 90px;
				}

				&-title {
					font-size: 24px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.our-tools {
		&__cards {
			flex-wrap: wrap;

			&-item {
				margin-bottom: 10px;
				width: 49%;
			}
		}
	}
}

@media (max-width: 320px) {
    .our-tools {
        &__title {
            font-size: 22px;
        }
    }
}

@-webkit-keyframes shadow-pop-bl {
    from {
        -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
                box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
      }
    to {
      -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
              box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
      -webkit-transform: translateX(8px) translateY(-8px);
              transform: translateX(8px) translateY(-8px);
    }
  }
  @keyframes shadow-pop-bl {
    from {
        -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
                box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
      }
    to {
      -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
              box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
      -webkit-transform: translateX(8px) translateY(-8px);
              transform: translateX(8px) translateY(-8px);
    }
  }

  @-webkit-keyframes shadow-pop-bl-out {
    0% {
        -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
                box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
        -webkit-transform: translateX(8px) translateY(-8px);
                transform: translateX(8px) translateY(-8px);
      }
    100% {
      -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
              box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
  }
  @keyframes shadow-pop-bl-out {
    0% {
        -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
                box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
        -webkit-transform: translateX(8px) translateY(-8px);
                transform: translateX(8px) translateY(-8px);
      }
    100% {
      -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
              box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
  }