.not-found {
    width: 100% auto;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__img {
        margin-bottom: 30px;
        max-width: 800px;
        width: 100%;
    }

    &__lang {
        -webkit-animation: rotate-in-2-cw 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: rotate-in-2-cw 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
}

@media (max-width: 768px) {
    .not-found {
        &__lang {
            width: 50%;
        }
    }
}

@-webkit-keyframes rotate-in-2-cw {
    0% {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-2-cw {
    0% {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
  }
  