html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'NeueMachina', 'PerfectlyAmicable',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background: #E7E8ED;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NeueMachina';
  src: local('NeueMachina'), 
       url(font/NeueMachina-Regular.eot) format('embedded-opentype'),
       url(font/NeueMachina-Regular.woff2) format('woff2'),
       url(font/NeueMachina-Regular.woff) format('woff'),
       url(font/NeueMachina-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PerfectlyAmicable';
  src: local('PerfectlyAmicable'), 
       url(font/perfectly\ amicable.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'),
       url('./font/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'),
       url('./font/Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'),
       url('./font/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'),
       url('./font/Montserrat/Montserrat-Bold.ttf') format('truetype');
}